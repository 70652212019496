import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { device, useContentfulSection, useMeta } from "utils"
import Creations from "src/components/sections/creations/Creations"

// import { AboutTeam, AboutTestimonial } from "src/components/sections/about"

const CreationPage = ({ data: { creations } }) => {
  const sections = useContentfulSection(creations.sections)
  const meta = useMeta(creations)

  return (
    <Layout hideFooter>
      <Seo {...meta} />
      <Nav />
      <Wrap>
        <Creations {...sections.creations} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div`
  background: #f9f9f9;
  padding-top: 25vh;

  @media ${device.laptop} {
    padding-top: 15vh;
  }
`

export const query = graphql`
  {
    creations: contentfulPage(pageId: { eq: "creations" }) {
      ...Page
    }
  }
`

export default CreationPage
