import React from "react"
import styled from "styled-components"
import { motion } from "framer-motion"
import spin_text from "src/images/creations/spin_text.svg"
import live_site_arrow from "src/images/creations/live_site_arrow.svg"
import classNames from "classnames"

const SeeLiveSite = ({ cursorActive, cursorX, cursorY, dark }) => {
  return (
    <StyledSeeLiveSite
      className={classNames("see-live-site", { dark })}
      animate={{
        opacity: cursorActive ? 1 : 0,
        scale: cursorActive ? 1 : 0,
      }}
      style={{ x: cursorX, y: cursorY }}
    >
      <motion.img
        className="spin_text"
        animate={{ rotate: 360 }}
        transition={{ ease: "linear", duration: 20, repeat: Infinity }}
        src={spin_text}
        loading="lazy"
        alt="spin text"
      />

      <img
        className="live_arrow"
        src={live_site_arrow}
        loading="lazy"
        alt="go"
      />
    </StyledSeeLiveSite>
  )
}

const StyledSeeLiveSite = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;

  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  box-shadow: 0px 4px 16px rgba(186, 186, 186, 0.16);
  background: rgba(249, 249, 249, 0.08);
  backdrop-filter: blur(12px);

  &.dark {
    .spin_text,
    .live_arrow {
      filter: invert(1);
    }
  }

  .spin_text {
    padding: 15px;
  }

  .live_arrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
`

export default SeeLiveSite
