import React from "react"
import styled from "styled-components"

import { TextGroup } from "src/components/common"
import CreationCard from "./CreationCard"
import { device } from "src/utils"

const Creations = ({ title, subtitle, components: creations }) => {
  return (
    <StyledCreations>
      <TextGroup
        className="hero"
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        subtitleAs="p"
      />

      <CreationCards creations={creations} />
    </StyledCreations>
  )
}

const StyledCreations = styled.div`
  /* padding: 0 var(--sp-24); */

  display: flex;
  flex-direction: column;
  align-items: center;

  .hero {
    .title {
      text-align: center;
      color: var(--creations-title-color);
      max-width: 780px;
      margin: 0 auto;
    }
    ul {
      display: flex;
      align-items: center;

      flex-direction: column;
      grid-gap: var(--sp-8);

      max-width: 700px;
      margin: 0 auto;
      margin-top: var(--sp-24);

      @media ${device.laptop} {
        margin-top: var(--sp-32);
        justify-content: center;
        flex-direction: row;
        flex-wrap: wrap;
        grid-column-gap: var(--sp-56);
        grid-row-gap: var(--sp-16);
      }

      p {
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 32px;
        text-align: center;
        color: var(--creations-tags-color);
      }
    }
  }
`

const CreationCards = ({ creations }) => {
  // light text and svg hover...
  const darkLib = {
    SkySQL: true,
    "Orpheus Ocean": true,
    "Multitude Insights": true,
    Starnet: true,
    Librty: true,
    Xeal: true,
    Deepwaters: true,
    Opero: true,
    "Benefit Bottle": true,
    Sleepwhale: true,
    "Top Corner": true,
  }

  const renderCards = () =>
    creations.map((card) => {
      return <CreationCard key={card.id} {...card} dark={darkLib[card.name]} />
    })

  return <StyledCreationCards>{renderCards()}</StyledCreationCards>
}

const StyledCreationCards = styled.ul`
  display: flex;
  flex-direction: column;
  grid-gap: var(--sp-16);
  width: 100%;
  height: auto;

  max-width: 1300px;
  /* margin: 0 auto; */
  margin-top: 80px;

  @media ${device.laptop} {
    margin-top: 130px;
  }
`

export default Creations
