import React, { useCallback, useState } from "react"
import { motion, useMotionValue, circOut } from "framer-motion"
import styled from "styled-components"

import { Image } from "src/components/common"
import { device } from "src/utils"

import SeeLiveSite from "./SeeLiveSite"

import liveArrow from "src/images/creations/live_site_arrow.svg"
import classNames from "classnames"

const reMap = (value, oldRange, newRange) => {
  var newValue =
    ((value - oldRange[0]) * (newRange[1] - newRange[0])) /
      (oldRange[1] - oldRange[0]) +
    newRange[0]
  return Math.min(Math.max(newValue, newRange[0]), newRange[1])
}

const CreationCard = ({
  name,
  tags,
  url,
  imageDesktop,
  imageMobile,
  dark,
  inactive,
}) => {
  const [cursorActive, setCursorActive] = useState(false)

  const cursorX = useMotionValue(0)
  const cursorY = useMotionValue(0)

  const [rotation, setRotation] = useState({
    rotateX: 0,
    rotateY: 0,
  })

  // const x = useMotionValue(0)
  // const y = useMotionValue(0)

  // const rotateX = useTransform(x, [0, 1], [0.25, -0.25], { ease: circOut })
  // const rotateY = useTransform(y, [0, 1], [-0.25, 0.25], { ease: circOut })

  const renderTags = useCallback(
    () =>
      tags.map((tag) => (
        <li key={tag}>
          {tag}
          <span>,</span>
        </li>
      )),
    [tags]
  )

  const handleMouse = ({ clientX, clientY, currentTarget }) => {
    const cover = currentTarget.getElementsByClassName("image-wrap")[0]

    // x.set(event.pageX)
    // y.set(event.pageY)
    const rect = cover.getBoundingClientRect()

    const xPos = clientX - rect.left
    const yPos = clientY - rect.top

    const xp = xPos / rect.width

    const yp = yPos / rect.height

    cursorX.set(xPos - 75)
    cursorY.set(yPos - 75)

    // x.set(yp)
    // y.set(xp)

    setRotation({
      rotateX: reMap(yp, [0, 1], [-0.25, 0.25]) * -1, // the remap thing doesnt work like useTransform so i just reverse it to match useTransform format...
      rotateY: reMap(xp, [0, 1], [-0.25, 0.25]),
    })
  }

  const handleReset = () => {
    // x.set(0)
    // y.set(0)

    setRotation({
      rotateX: 0,
      rotateY: 0,
    })
  }

  const handleMouseEnter = () => setCursorActive(true)
  const handleMouseLeave = () => setCursorActive(false)

  return (
    <StyledCreationCard
      initial={{ opacity: 0, y: 40 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{
        once: true,
        // margin: "-30%",
      }}
      className={name}
    >
      <a
        href={inactive ? "javascript:void(0)" : url}
        target={!inactive && "_blank"}
        rel="noreferrer"
        className="image-perspective"
        onMouseMove={handleMouse}
        onMouseLeave={handleReset}
      >
        <motion.div
          className="image-wrap"
          // style={{ rotateX, rotateY }}
          animate={{
            rotateX: rotation.rotateX,
            rotateY: rotation.rotateY,
          }}
          transition={{
            duration: 0.3,
            ease: circOut,
          }}
        >
          <Image
            className="cover"
            image={imageDesktop}
            mobileImage={imageMobile}
          />

          {!inactive && (
            <SeeLiveSite
              cursorActive={cursorActive}
              cursorX={cursorX}
              cursorY={cursorY}
              dark={dark}
            />
          )}

          {!inactive && (
            <div className={classNames("mobileSeeLive", { dark })}>
              <span className="text">see live website</span>
              <img src={liveArrow} alt="arrow" />
            </div>
          )}
        </motion.div>

        <div
          className="mouse-overlay"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      </a>

      <div className="bottom">
        <h3 className="name">{name}</h3>
        <ul className="tags">{renderTags()}</ul>
      </div>
    </StyledCreationCard>
  )
}

const StyledCreationCard = styled(motion.li)`
  overflow: hidden;

  cursor: none;

  .image-perspective {
    perspective: 200px;
    display: block;
    position: relative;
    cursor: none;
    /* overflow: hidden; */

    .image-wrap {
      /* overflow: hidden; */

      .cover {
        img {
          height: auto;
          width: 100%;
          object-position: center bottom;
        }
      }

      .mobileSeeLive {
        position: absolute;
        bottom: 8px;
        right: 8px;
        display: flex;
        align-items: center;
        grid-gap: var(--sp-8);

        box-shadow: 0px 4px 16px rgba(186, 186, 186, 0.16);
        background: rgba(249, 249, 249, 0.08);
        backdrop-filter: blur(12px);

        border-radius: 8px;
        padding: 8px;

        .text {
          color: black;
          font-family: "PP Mori";
          font-size: 0.875rem;
          line-height: 1;
          font-weight: 600;
        }

        img {
          width: 30px;
          height: auto;
        }

        &.dark {
          .text {
            color: white;
          }
          img {
            filter: invert(1);
          }
        }

        @media ${device.laptop} {
          display: none;
        }
      }
    }

    .mouse-overlay {
      position: absolute;
      top: 5%;
      left: 5%;
      bottom: 5%;
      right: 5%;
    }
  }

  .bottom {
    padding: var(--sp-16);
    display: flex;
    flex-direction: column;

    @media ${device.laptop} {
      padding: var(--sp-24) var(--sp-32);
      align-items: center;
      flex-direction: row;
    }
    .name {
      font-family: "PP Mori";
      font-weight: 600;
      color: var(--creation-name-color);
    }

    .tags {
      display: flex;
      flex-wrap: wrap;
      grid-gap: var(--sp-4);

      @media ${device.laptop} {
        margin-top: 0;
        margin-left: var(--sp-24);
      }

      li {
        color: var(--creation-tag-color);

        @media ${device.laptop} {
          margin-top: 0;
        }

        font-weight: 600;
        &:last-child {
          span {
            display: none;
          }
        }
      }
    }
  }
`

export default CreationCard
